import React, { useEffect, useState, useRef, createRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SkipNextSharpIcon from '@material-ui/icons/SkipNextSharp';
import SkipPreviousSharpIcon from '@material-ui/icons/SkipPreviousSharp';
import HTMLFlipBook from 'react-pageflip';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import DownloadIcon from '@mui/icons-material/Download';
const MagPlayer = (props) => {
  const { data } = props;
  let data1 = data && {
    ...data,
    pages: data.pages && Object.values(data.pages),
  };
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const flipPage = useRef(null);
  const [width, setWidth] = useState(window.innerWidth);
  let nbPages = data1 && data1.pages && data1.pages.length;
  let nbFlips = flipPage && flipPage.current && flipPage.current.getPageFlip().getPageCount();
  const [fullScreenMode, setfullScreenMode] = useState(false);
  const handle = useFullScreenHandle();
  const magPages = data1?.content.rtl ? data1.pages.reverse() : data1.pages

  useEffect(() => {
    //setTotalPage(flipPage.current.getPageFlip().getPageCount());
    if (nbFlips > 0 && nbFlips !== nbPages) {
      window.location.reload();
    }
    window.addEventListener('resize', updateDimensions);
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
      window.removeEventListener('resize', updateDimensions);
    };
  }, [data, flipPage, totalPage]);
  const [fullscreen, setFullscreen] = useState(false);

  const fullScreenToggler = () => {
    setfullScreenMode(!fullScreenMode);
  };

  const onPage = (e) => {
    setPage(e.data);
  };
  const next = () => {
    flipPage.current.getPageFlip().flipNext();
  };
  const prev = () => {
    flipPage.current.getPageFlip().flipPrev();
  };

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  const handleKeyDown = (event) => {
    if (event.keyCode === 37) {
      prev();
    }
    if (event.keyCode === 39) {
      next();
    }
  };
  const handleFullscreen = (event) => {

    setFullscreen(!fullscreen);
    if (fullscreen) {
      // flipPage.current.PageRect()
      //  setWidth(700);
      //  setHeight(1400);
      //(flipPage.current).turn('size', width <= 768 ? '350' : '700', 500);
    } else {
      //(flipPage.current).turn('size', width <= 768 ? '350' : '1400', 1000);
      // document.querySelector("#screen").requestFullscreen();
    }
    // $('.players').toggleClass('fullscreen');
    // $('body').toggleClass('overflow-hidden');
  };
  const handleDownloadMagazine = () => {
    const attachments = data?.attachments || [];

    if (attachments.length > 0) {
      attachments.forEach((attachment, index) => {
        const fileUrl = attachment?.url;

        if (fileUrl) {
          const link = document.createElement('a');
          link.href = fileUrl;
          link.setAttribute('download', `magazine_${index + 1}.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error(`Download URL for attachment ${index + 1} not found`);
        }
      });
    } else {
      console.error("No attachments found");
    }
  };


  return (
    <div>
      {data1 && data1.pages && data1.pages.length > 0 ? (
        <div className="players">
          {fullScreenMode == false ? (
            <div>
              <ul className="magazine-control-bar list-inline">
                <IconButton key="prev" aria-label="previous" onClick={prev}>
                  <SkipPreviousSharpIcon />
                </IconButton>
                <IconButton key="next" aria-label="next" onClick={next}>
                  <SkipNextSharpIcon />
                </IconButton>
                <IconButton key="full" aria-label="full" onClick={fullScreenToggler}>
                  {fullScreenMode === true ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
                {data?.attachments.length > 0 && data?.locked === false && <IconButton key="full" aria-label="full" onClick={handleDownloadMagazine}>
                  <DownloadIcon />
                </IconButton>}

              </ul>

              <HTMLFlipBook
                size="stretch"
                responsive={true}
                width={500}
                height={600}
                showTouchHint={true}
                autoSize={true}
                clickEventForward={true}
                useMouseEvents={true}
                drawShadow={true}
                ref={flipPage}
                mobileScrollSupport={true}
                onFlip={onPage}
                startPage={data1?.content.rtl ? data1.pages.length - 1 : 0}
              >
                {data1 &&
                  data1.pages &&
                  data1.pages.length > 0 &&
                  magPages.map((page, index) => (
                    <div>
                      <img src={page.url} style={{ width: '100%', height: '100%' }} />
                    </div>
                  ))}
              </HTMLFlipBook>
            </div>
          ) : (
            <FullScreen handle={fullScreenMode === true ? handle : ''}>
              <ul className="magazine-control-bar list-inline">
                <IconButton key="prev" aria-label="previous" onClick={prev}>
                  <SkipPreviousSharpIcon />
                </IconButton>
                <IconButton key="next" aria-label="next" onClick={next}>
                  <SkipNextSharpIcon />
                </IconButton>
                <IconButton key="full" aria-label="full" onClick={fullScreenToggler}>
                  {fullScreenMode === true ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
              </ul>
              <HTMLFlipBook
                size="stretch"
                responsive={true}
                width={100}
                height={100}
                showTouchHint={true}
                autoSize={true}
                clickEventForward={true}
                useMouseEvents={true}
                drawShadow={true}
                ref={flipPage}
                mobileScrollSupport={true}
                onFlip={onPage}
                startPage={data1?.content.rtl ? data1.pages.length - 1 : 0}

              >
                {data1 &&
                  data1.pages &&
                  data1.pages.length > 0 &&
                  data1.pages.map((page, index) => (
                    <div>
                      <img src={page.url} style={{ width: '100%', height: '100%' }} />
                    </div>
                  ))}
              </HTMLFlipBook>
            </FullScreen>
          )}
        </div>
      ) : (
        <div>
          <iframe
            frameBorder="0"
            src={data1 && data1.datas && data1.datas.urlData}
            type="text/html"
            scrolling="no"
            marginWidth="0"
            marginHeight="0"
            allowFullScreen
            seamless="seamless"
            allowtransparency="true"
            width="80%"
            height="600px"
          ></iframe>
        </div>
      )}
    </div>
  );
};

MagPlayer.propTypes = {
  data: PropTypes.object,
};

export default MagPlayer;
