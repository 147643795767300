import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import email from '../../assets/img/icons/email.svg';
import Badge from '@material-ui/core/Badge';
import { getMessages } from '../../redux/slices/messages';
import { Typography } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import NoDataIllustration from '../NoDataIllustration';
import noMessagesIllustration from '../../assets/img/noMessagesIllustration.webp';
import i18n from '../../services/i18n';
import { ReactComponent as MailIcon } from '../../assets/img/icons/mail-02.svg'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import * as dayjs from 'dayjs';
import { Box } from '@mui/material';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ar'; // Arabic
import 'dayjs/locale/fr'; // French
import 'dayjs/locale/en'; // English
const useStyles = makeStyles((theme) => ({
  dropdown: {
    marginLeft: '8px',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}));

const Index = ({ userId }) => {
  const { t } = useTranslation();
  const { messages } = useSelector((state) => state.messages);
  dayjs.extend(relativeTime);

  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const isArabic = i18n.language === 'ar';
  let nbMessages = 0;
  const getDayjsLocale = (language) => {
    switch (language) {
      case 'ar':
        return 'ar';
      case 'fr':
        return 'fr';
      default:
        return 'en';
    }
  };
  dayjs.locale(getDayjsLocale(i18n.language));

  messages && messages?.length && (nbMessages = messages.filter((message) => !message.seen).length);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (userId) {
      dispatch(getMessages(userId));
    }
  }, [userId]);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const filteredMessages = selectedTab === 0
    ? messages
    : messages?.filter(message => !message.seen);

  return (
    <div className={classes.dropdown + ' message'}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge color="secondary" badgeContent={nbMessages} showZero>
          <img alt="message" src={email} className="message-icon" />
        </Badge>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={isArabic ? 'bottom-start' : 'bottom-end'}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              marginTop: '1.5rem',
              marginLeft: '0',
              width: '70vw',
              maxWidth: '450px',
              minWidth: '280px',
              zIndex: 1200,
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>


                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <Typography className="menu-item-title">{t('Messages')}</Typography>
                  <Box className={"message-filter-container"} sx={{ borderBottom: 1, borderColor: 'divider' }}>

                    <Tabs
                      value={selectedTab}
                      onChange={handleTabChange}
                      indicatorColor="primary"
                      width={100}
                      textColor="primary"
                      aria-label="message tabs"

                    >
                      <Tab label={<span style={{ fontSize: "16px" }}>{t('all notifications')}</span>} iconPosition="end" icon={<span className='messages-numbers' style={{ background: selectedTab === 0 ? "#D2E8F2" : "#F1F1F1" }}> {messages.length}</span>} />
                      <Tab label={<span style={{ fontSize: "16px" }}>{t('unread_messages')} </span>} iconPosition="end" icon={<span className='messages-numbers' style={{ background: selectedTab === 1 ? "#D2E8F2" : "#F1F1F1" }} > {messages?.filter(message => !message?.seen).length}</span>} />
                    </Tabs>
                  </Box>

                  {filteredMessages && filteredMessages.length ? (
                    filteredMessages.map((message, index) => (
                      <MenuItem
                        onClick={handleClose}
                        key={message._id}
                        style={{ backgroundColor: 'transparent' }}
                      >
                        <Link to={`/inbox/${message?._id}`} style={{ width: "100%" }}>
                          <div className="message-header" style={{ opacity: message?.seen ? "0.6" : "1" }}>
                            <div className='col1'>
                              <MailIcon />
                              <Typography className="message-title">
                                {message?.message?.name}
                              </Typography>
                            </div>
                            <Typography className="message-time">
                              {dayjs(message?.message?.created_at).fromNow()}
                            </Typography>
                          </div>
                        </Link>
                      </MenuItem>
                    ))
                  ) : (
                    <NoDataIllustration
                      image={noMessagesIllustration}
                      text={t('Pas de messages')}
                      title="noMessageIllustration"
                    />
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default Index;
