import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { jsPDF } from 'jspdf';
import uploadCloud from '../assets/img/icons/upload-cloud.svg';

function FileDropeZone(props) {
  const { setFiles } = props;

  const onDrop = useCallback(async (acceptedFiles) => {
    const imageFiles = acceptedFiles.filter((file) =>
      file.type.startsWith('image/')
    );

    if (imageFiles.length > 0) {
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF();

      const imagePromises = imageFiles.map(async (file, index) => {
        const imgData = await readFileAsDataURL(file);

        const img = new Image();
        img.src = imgData;

        await new Promise((resolve) => {
          img.onload = () => {
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const imgAspectRatio = img.width / img.height;

            let imgWidth;
            let imgHeight;

            if (imgAspectRatio > 1) {
              imgWidth = pageWidth;
              imgHeight = pageWidth / imgAspectRatio;
            } else {
              imgHeight = pageHeight;
              imgWidth = pageHeight * imgAspectRatio;
            }

            const xOffset = (pageWidth - imgWidth) / 2;
            const yOffset = (pageHeight - imgHeight) / 2;

            pdf.addImage(imgData, 'JPEG', xOffset, yOffset, imgWidth, imgHeight);

            if (index < imageFiles.length - 1) {
              pdf.addPage();
            }

            resolve();
          };
        });
      });

      await Promise.all(imagePromises);

      const pdfBlob = pdf.output('blob');
      const pdfFile = new File([pdfBlob], 'merged-images.pdf', {
        type: 'application/pdf',
      });

      setFiles([pdfFile]);
    } else {
      setFiles(acceptedFiles);
    }
  }, [setFiles]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.gif,.jpg,.png,.pdf,.jpeg,.svg,application/pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx',
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const readFileAsDataURL = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })} className="dropzone">
        <input {...getInputProps()} />
        <p className="dropZoneText">Drop your file here or browse </p>
        <img src={uploadCloud} className="dropzoneIcon" alt="Upload Icon" />
      </div>
      <aside>
        <ul>{files}</ul>
      </aside>
    </section>
  );
}

export default FileDropeZone;
