import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import parse from 'html-react-parser';
import OfferCardNewPeriod from './OfferCardNewPeriod';
import OfferBuyButton from './OfferBuyButton';
import OfferDetails from './OfferDetails';
import { buyOffer } from '../../redux/slices/offers';
import bookMark from '../../assets/img/bookmark.png';
import { ReactComponent as OfferWave } from '../../assets/img/offer-wave.svg';
import { offerCardMaxWidth } from '../../utilities/constants';
import OfferSubjectDetails from './OfferSubjectDetails';
import OfferPriceCards from './OfferPriceCards';
import { formatMessages } from '../../utilities/translateHelper';
import { OffreConfirmation, CouponModal, OptimiumModal } from '../../components';
import UnsubscribeButton from './unsubscribeButton';

const OfferCard = ({ offer, updateOffer, setUpdateOffer, width, offerBookMark, offersLength }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { buyResult, loadingId, error, isLoading } = useSelector((state) => state.offers);
  const { permissions } = useSelector((state) => state.user);
  const { informations, digitalVirgoClient } = useSelector((state) => state.user);
  const [bacUpPercent, setBacUpPercent] = useState('');
  const { offers } = useSelector((state) => state.offers);
  const optimiumOffers = offers.filter((offer) => offer.name === "Optimum");
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const RevisionPackId = 84;
  const subjectPack = 119;
  const bacUp = 115;

  const GroupId = 85;
  const bacGroup = 122;
  const subjectPackGroup = 127;

  useEffect(() => {
    if (buyResult) {
      setUpdateOffer(!updateOffer);
    }
  }, [buyResult]);
  useEffect(() => {
    if (selectedSubjects) {
      setBacUpPercent('');
      if (selectedSubjects.length === 1) {
        setBacUpPercent('');
      }
      if (selectedSubjects.length === 2 && offer?.offer_id === bacUp) {
        setBacUpPercent('10');
      }
      if (parseInt(selectedSubjects.length) === 3 && offer?.offer_id === bacUp) {
        const promos = parseInt(subjectDetailsLength) === 3 ? '25' : '15';
        setBacUpPercent(promos);
      }
      if (
        ((parseInt(selectedSubjects.length) === subjectDetailsLength ||
          selectedSubjects.includes('all')) &&
          offer?.offer_id === RevisionPackId) ||
        offer?.offer_id === subjectPack ||
        parseInt(selectedSubjects.length) < 1
      ) {
        setBacUpPercent(25);
      }
    }
  }, [selectedSubjects]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [disableBuy, setDisableButton] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [buyOptimum, setBuyOptimum] = useState(false);
  const [suggestOptimium, setOpenSuggestOptimium] = useState(false);

  const [showSubjects, setShowMoreSubjects] = useState(false);
  const [selectedSubjectsSum, setSelectedSum] = useState(0);
  const currentPeriod =
    offer?.type === 'primary'
      ? offer?.pricing && offer?.pricing[offer?.pricing?.length - 1]?.period
      : '1';
  const [selectedPeriod, setPeriod] = useState(
    offer?.pricing && offer?.pricing?.some((item) => item.period === "reste de l'année")
      ? "reste de l'année"
      : currentPeriod
  );

  const [pricingIndex, setPricingIndex] = useState(offer?.pricing?.length - 1);
  const [pricingIndexOptimum, setPricingIndexOptimum] = useState(optimiumOffers?.[0]?.pricing?.length - 1);


  const [selectedLabel, setSelectedLabel] = useState(
    offer?.pricing && offer?.pricing?.some((item) => item.period === "reste de l'année")
      ? "reste de l'année"
      : offer?.type === 'primary'
        ? 'Par mois'
        : '1'
  );

  //const currentSelectedPeriod = offer?.pricing && ((offer?.pricing?.some(item=> item.period ==="reste de l'année"))) ? "reste de l'année": currentPeriod

  const offerLength = offer.pricing?.length - 1;
  const periodOfferLength =
    offer?.pricing &&
    offer?.pricing?.filter((item) => item.period !== "reste de l'année").length - 1;
  const freeOffer = offer?.offer?.is_free;
  const discount = offer?.pricing
    ? offer?.pricing[pricingIndex]?.infoDiscount?.discountType
    : offer?.infoDiscount?.discountType;
  const offerSubjectDesibled = informations?.offers?.filter(
    (offer) => offer.offer_division_subject !== null
  ).length;
  const offerHashed = informations?.offers?.filter(
    (offer) =>
      (offer.offer_division_subject && offer.offer_division_subject.subject.id !== null) ||
      ((offer.group.id === GroupId ||
        offer.group.id === bacGroup ||
        offer.group.id === subjectPackGroup) &&
        offer.offer_division_subject === null)
  ).length;

  const subjectDetailsLength =
    offer && offer.offer_subject_details ? offer.offer_subject_details?.length : null;
  const allYearExists = offer?.pricing?.some((item) => item.period === "reste de l'année");
  //const isBacUpPromo = (selectedSubjects.length > 1 && selectedSubjects.length < subjectDetailsLength) || (selectedSubjects.length === 1 && !selectedSubjects.includes('all'));
  const isBacUpPromo =
    (selectedSubjects.length > 1 && selectedSubjects.length < subjectDetailsLength) ||
    (selectedSubjects.length === 1 && !selectedSubjects.includes('all')) ||
    selectedSubjects.includes('all') ||
    offer?.offer_id === 84 ||
    offer?.offer_id === 119;

  const amountSum = (list, prix, basePrice, indexSliceToPay) => {
    let finalPrice = prix;
    list.map((item) => {
      finalPrice =
        item?.discount_code_offer_division?.discount_code?.on_base_price && indexSliceToPay !== 1
          ? basePrice
          : finalPrice;
      return (finalPrice +=
        item.discount_code_offer_division.type === 'percentage'
          ? -finalPrice * (item.discount_code_offer_division.amount / 100)
          : -item.discount_code_offer_division.amount);
    });
    return finalPrice;
  };

  const getPrice = (list, prix, basePrice, indexSliceToPay) => {
    let price;
    list.map((item) => {
      price =
        item?.discount_code_offer_division?.discount_code?.on_base_price && indexSliceToPay !== 1
          ? basePrice
          : prix;
    });
    return price;
  };

  const handleSubmitOffer = async () => {
    setDisableButton(true);
    setConfirmation(false);

    await dispatch(
      buyOffer(
        offer.id,
        selectedPeriod,
        null,
        null,
        selectedSubjects.length
          ? selectedSubjects.includes('all') || selectedSubjects.length === subjectDetailsLength
            ? null
            : selectedSubjects
          : null
      )
    );
    setOpenSnackBar(true);
    setDisableButton(false);
  };

  const handleSelectSubject = (subjectId, price) => {
    const existSubject = selectedSubjects?.find((subject) => subject === subjectId);
    if (existSubject) {
      let result = selectedSubjects.filter((subject) => subject !== existSubject);
      if (subjectId !== 'all' || selectedSubjects.length === subjectDetailsLength) {
        setSelectedSubjects(result);
        setSelectedSum((prevState) => parseInt(prevState) - parseInt(price));
        if (subjectId === 'all') {
          setSelectedSubjects([]);
          setSelectedSum(0);
        }
      } else {
        setSelectedSubjects([]);
        setSelectedSum(0);
      }
    } else {
      setSelectedSubjects((prevArray) => [...prevArray, subjectId]);
      setSelectedSum(selectedSubjectsSum);
      if (subjectId !== 'all') {
        setSelectedSum((prevState) => parseInt(prevState) + parseInt(price));
      }
    }
  };
  const finalSubjectPrice = (selectedSubjectsSum) => {
    //to be refactored this is an urgent demand
    let price = selectedSubjectsSum;
    let finalPrice = 0;
    if (subjectDetailsLength && offer.offer_id === bacUp) {
      if (selectedSubjects.length === 2) {
        finalPrice = selectedSubjectsSum * 0.1;
      }
      if (selectedSubjects.length === 3) {
        finalPrice =
          subjectDetailsLength === 3 ? selectedSubjectsSum * 0.25 : selectedSubjectsSum * 0.15;
      }
      if (selectedSubjects.includes('all') || selectedSubjects.length === subjectDetailsLength) {
        return offer.Finalprice;
      }
      return Math.ceil(selectedSubjectsSum - finalPrice);
    } else {
      return price;
    }
  };

  let offerDescription = offer?.description ? offer.description : offer?.offer_description;
  const showMoreExist =
    (offerDescription?.length > 991 && (width <= 1100 || width >= 1590)) ||
    (offerDescription.length > 993 && width > 1200);

  const handleConfiramtion = () => {
    // if (offer?.name === "بالمادّة" && selectedSubjects?.length > 1) {
    //   setOpenSuggestOptimium(true)
    //   return
    // }
    setConfirmation(true);
  };

  const handleShow = () => {
    setShowMoreSubjects(!showSubjects);
  };

  return (
    <Card
      className={`offer-card ${!offer.offer_subject_details?.length > 0 ? 'offer-subject' : ''}`}
      style={{ height: '100%' }}
    >
      <div
        className="card"
        style={{
          width:
            width / (2 + offersLength) <= offerCardMaxWidth
              ? width / (2 + offersLength)
              : offerCardMaxWidth,
        }}
      >
        <div className="offer-header">
          <OfferWave fill={offer?.color} />
          {offerBookMark && <img className="offer-bookmark" src={bookMark} />}
          <div className="offer-header-container" style={{ fill: offer?.color }}>
            {offer?.offer_id === 33 && digitalVirgoClient && (
              <div>
                <UnsubscribeButton />
              </div>
            )}
            <div className="offer-details">
              <Typography className="offer-name">{offer.name}</Typography>
              <div className="offer-price">
                {offer.type === 'primary' && offer.pricing[pricingIndex]?.discount && (
                  <Typography
                    style={{
                      paddingLeft: offer.pricing[pricingIndex]?.final_price ? '30px' : '0px',
                    }}
                  >
                    <span className="base-offer-price">
                      {' '}
                      {offer.pricing[pricingIndex]?.price}
                      {offer.pricing[pricingIndex]?.infoDiscount?.discountValue ? '' : 'Dt'}
                    </span>{' '}
                    {offer.pricing[pricingIndex]?.final_price
                      ? offer.pricing[pricingIndex]?.infoDiscount?.discountValue != null && (
                        <span id="Remise">
                          -
                          {offer.pricing[pricingIndex].infoDiscount.discountType ===
                            'percentage_discount'
                            ? offer.pricing[pricingIndex]?.infoDiscount.discountValue + '%'
                            : offer.pricing[pricingIndex].infoDiscount.discountType ===
                              'fixe_price'
                              ? offer.pricing[pricingIndex]?.price -
                              offer.pricing[pricingIndex]?.infoDiscount?.discountValue +
                              'Dt'
                              : offer.pricing[pricingIndex]?.infoDiscount?.discountValue + 'Dt'}
                        </span>
                      )
                      : ''}
                  </Typography>
                )}
                {offer.type === 'pack' &&
                  offer?.Baseprice &&
                  !offer?.subject_details?.length &&
                  !offer?.slices && (
                    <Typography style={{ paddingLeft: offer?.Finalprice ? '30px' : '0px' }}>
                      <span className="base-offer-price">
                        {selectedSubjects && selectedSubjects.length < subjectDetailsLength
                          ? ''
                          : isBacUpPromo
                            ? ''
                            : offer?.Baseprice}
                        {offer?.infoDiscount?.discountValue ? '' : 'Dt'}
                      </span>
                      {offer?.Finalprice
                        ? offer?.infoDiscount?.discountValue != null &&
                        selectedSubjects &&
                        (selectedSubjects.includes('all') ||
                          selectedSubjects.length === subjectDetailsLength ||
                          selectedSubjects.length < 1) && (
                          <span id="Remise">
                            -
                            {offer?.infoDiscount?.discountType === 'percentage_discount' &&
                              !isBacUpPromo
                              ? offer?.infoDiscount?.discountValue + '%'
                              : isBacUpPromo
                                ? bacUpPercent + '%'
                                : offer.infoDiscount?.discountValue}
                            {offer?.infoDiscount?.discountType !== 'percentage_discount' && (
                              <span id="dinnar">dt</span>
                            )}
                          </span>
                        )
                        : ''}
                    </Typography>
                    //     <Typography style={{ paddingLeft: offer?.Finalprice ? '30px' : '0px' }}>
                    //   <span className="base-offer-price">
                    //     {offer?.Baseprice}
                    //     {offer?.infoDiscount?.discountValue ? '' : 'Dt'}
                    //   </span>
                    //       {offer?.Finalprice
                    //           ? offer?.infoDiscount?.discountValue != null && (
                    //           <span id="Remise">
                    //           -
                    //             {offer?.infoDiscount?.discountType === 'percentage_discount'
                    //                 ? offer?.infoDiscount?.discountValue + '%'
                    //                 : offer.infoDiscount?.discountValue }
                    //             {offer?.infoDiscount?.discountType !== 'percentage_discount' && (
                    //                 <span id="dinnar">dt</span>
                    //             )}
                    //         </span>
                    //       )
                    //           : ''}
                    //     </Typography>
                  )}

                {offer.type === 'pack' && !offer?.subject_details?.length && (
                  <Typography className="final-offer-price">
                    {offer?.slices
                      ? offer?.slices.amount
                      : !selectedSubjects.includes('all') &&
                        selectedSubjects.length !== subjectDetailsLength &&
                        selectedSubjects.length > 0
                        ? finalSubjectPrice(selectedSubjectsSum)
                        : finalSubjectPrice(offer?.Finalprice) ||
                        finalSubjectPrice(offer?.price)}{' '}
                    Dt
                  </Typography>
                )}
                {offer.type === 'primary' &&
                  (offer.pricing.length > 0 ? (
                    <Typography className="final-offer-price">
                      {offer.pricing[pricingIndex]?.final_price
                        ? offer.pricing[pricingIndex]?.final_price
                        : offer.pricing[pricingIndex]?.price}{' '}
                      {selectedPeriod === "reste de l'année" || selectedLabel === 'Par mois'
                        ? 'Dt'
                        : ''}
                    </Typography>
                  ) : (
                    <Typography className="final-offer-price">{t('Gratuit')}</Typography>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {freeOffer === false &&
          offer.type === 'primary' &&
          offer.name !== 'Silver' &&
          offer.offer_id !== 102 && (
            <OfferCardNewPeriod
              setPeriod={setPeriod}
              indexButton={offer?.pricing[periodOfferLength].period}
              isNewPeriod={false}
              allYearExists={allYearExists}
              classCard={'down'}
              color={offer.color}
              pricings={offer.pricing}
              setSelectedLabel={setSelectedLabel}
              selectedLabel={selectedLabel}
              setPricingIndex={setPricingIndex}
              offerLength={periodOfferLength}
              offerAllYearLength={offerLength}
              width={
                width / (2 + offersLength) <= offerCardMaxWidth
                  ? width / (2 + offersLength)
                  : offerCardMaxWidth
              }
            />
          )}
        {freeOffer === false &&
          offer.type === 'primary' &&
          offer.name === 'Silver' &&
          offer.offer_id !== 102 && (
            <OfferCardNewPeriod
              classCard={'down'}
              setPeriod={setPeriod}
              indexButton={offer?.pricing[periodOfferLength].period}
              isNewPeriod={true}
              allYearExists={allYearExists}
              color={offer.color}
              pricings={offer.pricing}
              setPricingIndex={setPricingIndex}
              pricingIndex={pricingIndex}
              offerLength={periodOfferLength}
              offerAllYearLength={offerLength}
              setSelectedLabel={setSelectedLabel}
              selectedLabel={selectedLabel}
              width={
                width / (2 + offersLength) <= offerCardMaxWidth
                  ? width / (2 + offersLength)
                  : offerCardMaxWidth
              }
            />
          )}
        {!offer?.offer_subject_details?.length && (
          <OfferBuyButton
            color={offer.color}
            button={t(
              formatMessages(
                `${(offer.offer_id === RevisionPackId ||
                  offer.offer_id === bacUp ||
                  offer.offer_id === subjectPack) &&
                  offerSubjectDesibled !== offer.offer_subject_details?.length &&
                  offerSubjectDesibled !== 0
                  ? 'Acheter'
                  : offer.Button
                }`
              )
            )}
            handleSubmit={handleConfiramtion}
            isLoading={loadingId}
            offerId={offer.id}
            disabled={
              offer.offer_id === RevisionPackId ||
                offer.offer_id === bacUp ||
                offer.offer_id === subjectPack
                ? offerSubjectDesibled === offer.offer_subject_details?.length
                : offer?.disabled
            }
            sliceAmount={offer?.slices?.slice?.amount}
            disableButton={isLoading}
          />
        )}
        {
          <OfferDetails
            description={parse(offerDescription)}
            offerType={offer.type}
            showMore={showMore}
            showMoreExist={showMoreExist}
            width={width}
          />
        }
        {offer.offer_subject_details?.length > 0 && showSubjects && (
          <div>
            {offer.offer_subject_details?.length ? (
              <button
                style={{
                  backgroundColor: 'white',
                  color: offer.color,
                  border: `1.8px solid ${offer.color}`,
                }}
                className={`ShowMoreButton`}
                onClick={handleShow}
              >
                <div className={'ShowMoreButtonText'}>{t('Choisir une matière')}</div>
              </button>
            ) : (
              ''
            )}
            {offer.offer_subject_details.map((subject) => {
              return (
                <OfferSubjectDetails
                  name={t(formatMessages(`${subject.subject}`))}
                  description={subject.description}
                  color={offer.color}
                  handleSelectSubject={handleSelectSubject}
                  selectedSubjects={selectedSubjects}
                  disabledOption={informations?.offers?.find(
                    (offer) =>
                      (offer.offer_division_subject &&
                        offer.offer_division_subject.subject.id === subject.subjectId) ||
                      ((offer.group.id === GroupId ||
                        offer.group.id === bacGroup ||
                        offer.group.id === subjectPackGroup) &&
                        offer.offer_division_subject === null)
                  )}
                  id={subject.id}
                  price={subject.price}
                  offers={informations?.offers}
                />
              );
            })}
            <OfferSubjectDetails
              name={t('Toutes les matières')}
              // description={`Vous bénéficiez d\'une promotion de   ${
              //   '25%' || ''
              // } pour l\'achat du Pack complet.`}
              color={offer.color}
              selectedSubjects={selectedSubjects}
              id={'all'}
              handleSelectSubject={handleSelectSubject}
              disabledOption={offerHashed > 0}
              subjectDetailsLength={subjectDetailsLength}
            />
          </div>
        )}
        {!offer?.offer?.is_free &&
          offer.type === 'primary' &&
          offer.name !== 'Silver' &&
          offer.offer_id !== 102 && (
            <OfferCardNewPeriod
              setPeriod={setPeriod}
              indexButton={offer?.pricing[periodOfferLength].period}
              isNewPeriod={false}
              allYearExists={allYearExists}
              classCard={'down'}
              color={offer.color}
              pricings={offer.pricing}
              setPricingIndex={setPricingIndex}
              setSelectedLabel={setSelectedLabel}
              selectedLabel={selectedLabel}
              offerLength={periodOfferLength}
              offerAllYearLength={offerLength}
              isFree={offer?.offer?.is_free}
              width={
                width / (2 + offersLength) <= offerCardMaxWidth
                  ? width / (2 + offersLength)
                  : offerCardMaxWidth
              }
            />
          )}
        {!offer?.offer?.is_free &&
          offer.type === 'primary' &&
          offer.name === 'Silver' &&
          offer.offer_id !== 102 && (
            <OfferCardNewPeriod
              setPeriod={setPeriod}
              indexButton={offer?.pricing[periodOfferLength].period}
              classCard={'down'}
              isNewPeriod={true}
              allYearExists={allYearExists}
              color={offer.color}
              pricings={offer.pricing}
              setPricingIndex={setPricingIndex}
              setSelectedLabel={setSelectedLabel}
              selectedLabel={selectedLabel}
              offerLength={periodOfferLength}
              offerAllYearLength={offerLength}
              isFree={offer.offer.is_free}
              width={
                width / (2 + offersLength) <= offerCardMaxWidth
                  ? width / (2 + offersLength)
                  : offerCardMaxWidth
              }
            />
          )}
        {!!offer.offer_subject_details?.length &&
          showSubjects &&
          selectedLabel === "reste de l'année" && (
            <OfferPriceCards
              subjects={offer.offer_subject_details}
              color={offer.color}
              handleSelectSubject={handleSelectSubject}
              selectedSubjects={selectedSubjects}
              totalPrice={selectedSubjectsSum}
              offerBasePrice={offer.Baseprice || ''}
              discount={offer.Finalprice || false}
              subjectDetailsLength={subjectDetailsLength}
              offerPrice={offer.Finalprice || offer.price}
            />
          )}
        {offer.offer_subject_details?.length > 0 && showSubjects === false ? (
          <button
            style={{
              backgroundColor: 'white',
              color: offer.color,
              border: `1.8px solid ${offer.color}`,
            }}
            className={`ShowMoreButton`}
            onClick={handleShow}
          >
            <div className={'ShowMoreButtonText'}>{t('Choisir une matière')}</div>
          </button>
        ) : (
          <></>
        )}
        <OfferBuyButton
          color={offer.color}
          button={t(
            formatMessages(
              `${(offer.offer_id === RevisionPackId ||
                offer.offer_id === bacUp ||
                offer.offer_id === subjectPack) &&
                offerSubjectDesibled !== offer.offer_subject_details?.length &&
                offerSubjectDesibled !== 0
                ? t('Acheter')
                : offer.Button
              }`
            )
          )}
          handleSubmit={handleConfiramtion}
          isLoading={loadingId}
          offerId={offer.id}
          disabled={
            offer.offer_id === RevisionPackId ||
              offer.offer_id === bacUp ||
              offer.offer_id === subjectPack
              ? offerSubjectDesibled === offer.offer_subject_details?.length ||
              informations?.offers?.filter(
                (offer) =>
                  (offer.group.id === GroupId ||
                    offer.group.id === bacGroup ||
                    offer.group.id === subjectPackGroup) &&
                  offer.offer_division_subject === null
              ).length ||
              (offerSubjectDesibled !== offer.offer_subject_details?.length &&
                offerHashed !== 0 &&
                selectedSubjects.length === 0)
              : offer.disabled
          }
          sliceAmount={offer?.slices?.slice?.amount}
          disableButton={isLoading}
        />
        <OffreConfirmation
          handleClick={handleSubmitOffer}
          setOpen={setConfirmation}
          offre={offer.name}
        />
        {/* <OptimiumModal setOpen={setOpenSuggestOptimium} open={suggestOptimium} setBuyOptimum={setBuyOptimum} setConfirmation={setConfirmation} /> */}
        {confirmation && (
          <CouponModal
            setPeriod={setPeriod}
            selectedPeriod={selectedPeriod}
            setSelectedLabel={setSelectedLabel}
            selectedLabel={selectedLabel}
            open={confirmation}
            setOpen={setConfirmation}
            handleAcheter={handleSubmitOffer}
            getPrice={getPrice}
            basePrice={
              offer.type === 'pack' ? offer?.Baseprice : offer?.pricing[pricingIndex]?.price
            }
            prix={
              offer.type === 'primary'
                ? offer?.pricing[pricingIndex]?.final_price
                : (offer.type === 'primary' || offer.type === 'pack') &&
                  ![RevisionPackId, bacUp, subjectPack].includes(offer.offer_id) &&
                  offer.Button !== ('Acheter' || 'Prolonger')
                  ? offer?.slices?.amount
                  : offer.type === 'pack' &&
                    offer?.Finalprice &&
                    offer.offer_id !== RevisionPackId &&
                    offer.offer_id !== bacUp &&
                    offer.offer_id !== subjectPack
                    ? offer?.Finalprice
                    : ([RevisionPackId, bacUp, subjectPack].includes(offer.offer_id) &&
                      (selectedSubjects.includes('all') === true ||
                        selectedSubjects.length === subjectDetailsLength)) ||
                      selectedSubjects.length === 0
                      ? finalSubjectPrice(offer?.Finalprice) || finalSubjectPrice(offer?.price)
                      : selectedSubjects.length === 0 &&
                        [RevisionPackId, bacUp, subjectPack].includes(offer.offer_id)
                        ? finalSubjectPrice(offer?.Finalprice) || finalSubjectPrice(offer?.price)
                        : (offer.offer_id === RevisionPackId ||
                          offer.offer_id === bacUp ||
                          offer.offer_id === subjectPack) &&
                          !selectedSubjects.includes('all') &&
                          selectedSubjects.length !== subjectDetailsLength &&
                          selectedSubjects.length > 0
                          ? finalSubjectPrice(selectedSubjectsSum)
                          : finalSubjectPrice(offer?.price)
            }
            priceNoCoupon={offer?.pricing ? offer?.pricing[pricingIndex]?.price : offer?.price}
            finalPriceNoCoupon={
              offer?.pricing ? offer?.pricing[pricingIndex]?.final_price : offer?.Finalprice
            }
            discountValueNoCoupon={
              offer?.pricing
                ? offer?.pricing[pricingIndex]?.infoDiscount?.discountValue
                : offer?.infoDiscount?.discountValue
            }
            discountType={discount}
            remise={permissions.discount}
            offer={offer.name}
            color={offer.color}
            amount={amountSum}
            offerId={offer.offer_id}
            isFree={offer.offer.is_free}
            indexSliceToPay={offer?.indexSliceToPay}
            weekly={offer.weeklyPrice}
            daily={offer?.dailyPrice}
            offerDivisionId={offer.id}
            typePeriod={selectedLabel}
            offerParam={offer}
            setPricingIndex={setPricingIndex}
            pricingIndex={pricingIndex}
            offerLength={offerLength}
          />
        )}
        {/* {buyOptimum && (
          <CouponModal
            setPeriod={setPeriod}
            selectedPeriod={selectedPeriod}
            setSelectedLabel={setSelectedLabel}
            selectedLabel={selectedLabel}
            open={buyOptimum}
            setOpen={setBuyOptimum}
            handleAcheter={handleSubmitOffer}
            getPrice={getPrice}
            basePrice={
              optimiumOffers?.[0].type === 'pack' ? optimiumOffers?.[0]?.Baseprice : optimiumOffers?.[0]?.pricing[pricingIndexOptimum]?.price
            }
            prix={
              optimiumOffers?.[0].type === 'primary'
                ? optimiumOffers?.[0]?.pricing[pricingIndexOptimum]?.final_price
                : (optimiumOffers?.[0].type === 'primary' || optimiumOffers?.[0].type === 'pack') &&
                  ![RevisionPackId, bacUp, subjectPack].includes(optimiumOffers?.[0].offer_id) &&
                  optimiumOffers?.[0].Button !== ('Acheter' || 'Prolonger')
                  ? optimiumOffers?.[0]?.slices?.amount
                  : optimiumOffers?.[0].type === 'pack' &&
                    optimiumOffers?.[0]?.Finalprice &&
                    optimiumOffers?.[0].offer_id !== RevisionPackId &&
                    optimiumOffers?.[0].offer_id !== bacUp &&
                    optimiumOffers?.[0].offer_id !== subjectPack
                    ? optimiumOffers?.[0]?.Finalprice
                    : ([RevisionPackId, bacUp, subjectPack].includes(optimiumOffers?.[0].offer_id) &&
                      (selectedSubjects.includes('all') === true ||
                        selectedSubjects.length === subjectDetailsLength)) ||
                      selectedSubjects.length === 0
                      ? finalSubjectPrice(optimiumOffers?.[0]?.Finalprice) || finalSubjectPrice(optimiumOffers?.[0]?.price)
                      : selectedSubjects.length === 0 &&
                        [RevisionPackId, bacUp, subjectPack].includes(optimiumOffers?.[0].offer_id)
                        ? finalSubjectPrice(optimiumOffers?.[0]?.Finalprice) || finalSubjectPrice(optimiumOffers?.[0]?.price)
                        : (optimiumOffers?.[0].offer_id === RevisionPackId ||
                          optimiumOffers?.[0].offer_id === bacUp ||
                          optimiumOffers?.[0].offer_id === subjectPack) &&
                          !selectedSubjects.includes('all') &&
                          selectedSubjects.length !== subjectDetailsLength &&
                          selectedSubjects.length > 0
                          ? finalSubjectPrice(selectedSubjectsSum)
                          : finalSubjectPrice(optimiumOffers?.[0]?.price)
            }
            priceNoCoupon={optimiumOffers?.[0]?.pricing ? optimiumOffers?.[0]?.pricing[pricingIndexOptimum]?.price : optimiumOffers?.[0]?.price}
            finalPriceNoCoupon={
              optimiumOffers?.[0]?.pricing ? optimiumOffers?.[0]?.pricing[pricingIndexOptimum]?.final_price : optimiumOffers?.[0]?.Finalprice
            }
            discountValueNoCoupon={
              optimiumOffers?.[0]?.pricing
                ? optimiumOffers?.[0]?.pricing[pricingIndexOptimum]?.infoDiscount?.discountValue
                : optimiumOffers?.[0]?.infoDiscount?.discountValue
            }
            discountType={discount}
            remise={permissions.discount}
            offer={optimiumOffers?.[0].name}
            color={optimiumOffers?.[0].color}
            amount={amountSum}
            offerId={optimiumOffers?.[0].offer_id}
            isFree={optimiumOffers?.[0].offer.is_free}
            indexSliceToPay={optimiumOffers?.[0]?.indexSliceToPay}
            weekly={optimiumOffers?.[0].weeklyPrice}
            daily={optimiumOffers?.[0]?.dailyPrice}
            offerDivisionId={optimiumOffers?.[0].id}
            typePeriod={selectedLabel}
            offerParam={optimiumOffers?.[0]}
            setPricingIndex={setPricingIndexOptimum}
            pricingIndex={pricingIndex}
            offerLength={offerLength}
          />
        )} */}
        {confirmation && offer.type === 'pack' && (
          <CouponModal
            setPeriod={setPeriod}
            selectedPeriod={selectedPeriod}
            setSelectedLabel={setSelectedLabel}
            selectedLabel={selectedLabel}
            open={confirmation}
            setOpen={setConfirmation}
            handleAcheter={handleSubmitOffer}
            getPrice={getPrice}
            basePrice={offer?.Baseprice}
            prix={
              offer.type === 'primary'
                ? offer?.pricing[pricingIndex]?.final_price
                : (offer.type === 'primary' || offer.type === 'pack') &&
                  offer.offer_id !== RevisionPackId &&
                  offer.offer_id !== bacUp &&
                  offer.offer_id !== subjectPack &&
                  offer.Button !== ('Acheter' || 'Prolonger')
                  ? offer?.slices?.amount
                  : offer.type === 'pack' &&
                    offer?.Finalprice &&
                    offer.offer_id !== RevisionPackId &&
                    offer.offer_id !== bacUp &&
                    offer.offer_id !== subjectPack
                    ? offer?.Finalprice
                    : ((offer.offer_id === RevisionPackId ||
                      offer.offer_id === bacUp ||
                      offer.offer_id === subjectPack) &&
                      (selectedSubjects.includes('all') === true ||
                        selectedSubjects.length === subjectDetailsLength)) ||
                      selectedSubjects.length === 0
                      ? finalSubjectPrice(offer?.Finalprice) || finalSubjectPrice(offer?.price)
                      : selectedSubjectsSum !== 0 &&
                        (offer.offer_id === RevisionPackId ||
                          offer.offer_id === bacUp ||
                          offer.offer_id === subjectPack) &&
                        (selectedSubjects.includes('all') === false ||
                          selectedSubjects.length !== subjectDetailsLength)
                        ? finalSubjectPrice(selectedSubjectsSum)
                        : finalSubjectPrice(offer?.price)
            }
            remise={permissions.discount}
            offer={offer.name}
            color={offer.color}
            amount={amountSum}
            offerId={offer.offer_id}
            weekly={offer.weeklyPrice}
            daily={offer?.dailyPrice}
            offerDivisionId={offer.id}
            typePeriod={selectedLabel}
            offerParam={offer}
            isFree={offer.offer.is_free}
            indexSliceToPay={offer?.indexSliceToPay}
            setPricingIndex={setPricingIndex}
            offerLength={offerLength}
          />
        )}
      </div>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'Bottom',
          horizontal: 'center',
        }}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert severity={error ? 'warning' : 'success'} onClose={() => setOpenSnackBar(false)}>
          {error ? t(formatMessages(error.message)) : t(formatMessages(buyResult.message))}
        </Alert>
      </Snackbar>
    </Card>
  );
};
OfferCard.propTypes = {
  buyResult: PropTypes.any,
  isLoading: PropTypes.any,
  loadingId: PropTypes.any,
  error: PropTypes.any,
};
export default OfferCard;
