import React from 'react';
import { useTranslation } from 'react-i18next';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { formatMessages } from '../../utilities/translateHelper';

const EventResult = (props) => {
  const { t } = useTranslation();
  const { link, join, setOpenSnackBar, setOpenLink, open, openSnackBar, error } = props;
  const openLink = (url) => {
    setOpenLink(false);
    // window.open(url, '_blank');
    window.location.href = url;
  };

  if (join) {
    return (
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'Bottom',
          horizontal: 'center',
        }}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          severity={join?.eventStartTime ? 'warning' : 'success'}
          onClose={() => setOpenSnackBar(false)}
        >
          {t(formatMessages(join?.message))}
        </Alert>
      </Snackbar>
    );
  }

  if (link && !open) {
    return <div onClick={openLink(link.joinUrl)}></div>;
  }
  if (error) {
    return (
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'Bottom',
          horizontal: 'center',
        }}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert severity={'error'} onClose={() => setOpenSnackBar(false)}>
          {t(formatMessages(error?.message))}
        </Alert>
      </Snackbar>
    );
  } else {
    return null;
  }
};

EventResult.propTypes = {
  link: PropTypes.any,
  join: PropTypes.any,
  open: PropTypes.any,
  loading: PropTypes.any,
  error: PropTypes.any,
  setOpenSnackBar: PropTypes.func,
  setOpenLink: PropTypes.func,
  openSnackBar: PropTypes.func,
};

export default EventResult;
